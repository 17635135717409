
import { saveAs } from "file-saver";
import { defineComponent, reactive, toRefs, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { hideModal } from "@/core/helpers/dom";
import { ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  mixins: [Globals],
  name: "change-status-modal",
  components: {},
  data() {
    return {
      value1: "",
      statusData: {
        application: "",
        organizationType: "",
        organizationId: "",
        consumerCode: "",
        data: {
          panNumber: "",
          inActiveDate: new Date(),
        },
      },
      // render: false,
      panNumber: "",
      consumerCode: "",
    };
  },
  methods: {
    callChange(pan, consumerCode) {
      this.panNumber = pan;
      this.consumerCode = consumerCode;
    },

    formReset() {
      (this.value1 = ""), (this.panNumber = "");
    },
    submit(date) {
      if (date == null) {
        ElNotification({
          title: "Error",
          message: "Date cannot be null",
          type: "error",
          duration: 2000,
          position: "top-right",
        });
      } else {
        this.statusData.application = "";
        this.statusData.organizationType = "M";
        this.statusData.consumerCode = this.consumerCode;
        this.statusData.organizationId = String(this.globalData.organizationId);
        this.statusData.data.panNumber = this.panNumber;
        var dd = "" + date.getDate();
        var mm = "" + (date.getMonth() + 1);
        var yyyy = "" + date.getFullYear();
        if (mm.length < 2) {
          mm = "0" + mm;
        }
        if (dd.length < 2) {
          dd = "0" + dd;
        }
        date =
          yyyy +
          "-" +
          mm +
          "-" +
          dd +
          "T" +
          "23" +
          ":" +
          "59" +
          ":" +
          "59" +
          "." +
          "000Z";
        this.statusData.data.inActiveDate = date;
        ApiService.put("/consumerHasManufacturer", this.statusData)
          .then((data) => {
            // loading.close();
            ElNotification({
              title: "Success",
              message: "Inactive date added",
              type: "success",
              duration: 2000,
              position: "top-right",
            });
            (this.$refs["resetForm"] as any).click();
            hideModal(this.changeStatusModalRef);
            this.$emit("changeTitle", "false");
          })
          .catch((error) => {
            // loading.close();
            ElNotification({
              title: "Error",
              message: JSON.stringify(
                error.response.data["errors"][0]["errorMessage"]
              ),
              type: "error",
              duration: 2000,
              position: "top-right",
            });
          });
      }
      this.value1 = "";
    },
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const changeStatusModalRef = ref<null | HTMLElement>(null);
    //   const formRef = ref<null | HTMLFormElement>(null);
    //   const loading = ref<boolean>(false);
    //   const state = reactive({
    //     shortcuts: [
    //       {
    //         text: "Last week",
    //         value: () => {
    //           const end = new Date();
    //           const start = new Date();
    //           start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    //           return [start, end];
    //         },
    //       },
    //       {
    //         text: "Last month",
    //         value: () => {
    //           const end = new Date();
    //           const start = new Date();
    //           start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    //           return [start, end];
    //         },
    //       },
    //       {
    //         text: "Last 3 months",
    //         value: () => {
    //           const end = new Date();
    //           const start = new Date();
    //           start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    //           return [start, end];
    //         },
    //       },
    //     ],
    //   });

    //   const formData = ref({
    //     dateRange: [],
    //     exportFormat: "",
    //   });

    //   const rules = ref({
    //     dateRange: [
    //       {
    //         required: true,
    //         message: "Date range is required",
    //         trigger: "change",
    //       },
    //     ],
    //   });

    //   const submit = () => {
    //     if (!formRef.value) {
    //       return;
    //     }

    //     formRef.value.validate((valid) => {
    //       if (valid) {
    //         loading.value = true;

    //         setTimeout(() => {
    //           loading.value = false;

    //           Swal.fire({
    //             text: "Exported Successfully!!",
    //             icon: "success",
    //             buttonsStyling: false,
    //             confirmButtonText: "Ok, got it!",
    //             customClass: {
    //               confirmButton: "btn btn-primary",
    //             },
    //           }).then(() => {
    //             // window.location.reload();
    //           });
    //         }, 2000);
    //       } else {
    //         Swal.fire({
    //           text: "Sorry, looks like there are some errors detected, please try again.",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });
    //         return false;
    //       }
    //     });
    //   };

    return {
      // ...toRefs(state),
      // formData,
      // rules,
      submitButtonRef,
      changeStatusModalRef,
      // formRef,
      // loading,
    };
  },
});
