
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";

import { MenuComponent } from "@/assets/ts/components";
import AttachConsumerModal from "@/components/modals/forms/AttachConsumerModal.vue";
import EditConsumerModal from "@/components/modals/forms/EditConsumerModal.vue";
import ChangeStatusModal from "@/components/modals/forms/ChangeStatusModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElLoading, ElNotification } from "element-plus";
import router from "@/router";
interface ICustomer {
  id: number;
  name: string;
  panNo: string;
  industry: string;
  email: string;
  consumerStatus: string;
}

export default defineComponent({
  mixins: [Globals],
  name: "ConfigureConsumer",
  components: {
    Datatable,
    // AddConfigureConsumerModal,
    AttachConsumerModal,
    EditConsumerModal,
    ChangeStatusModal,
  },

  data() {
    return {
      configureconsumerdata: [],
      render: false,
      dialogVisible: false,
      isActive: false,
      value1: new Date(),
      initCust: [] as ICustomer[],
      search: "",
      searchFlag:true,
      statusData: {
        application: "",
        organizationType: "",
        organizationId: "",
        data: {
          panNumber: "",
          inActiveDate: new Date(),
        },
      },
    };
  },

  methods: {

     searchItems() {
      this.configureconsumerdata["data"].splice(
        0,
        this.configureconsumerdata["data"].length,
        ...this.initCust
      );

      if (this.search !== "") {
        let results = [] as ICustomer[];
        for (let j = 0; j < this.configureconsumerdata["data"].length; j++) {
          if (this.searchingFunc(this.configureconsumerdata["data"][j], this.search)) {
            results.push(this.configureconsumerdata["data"][j]);
          }

        }
        this.configureconsumerdata["data"].splice(
          0,
          this.configureconsumerdata["data"].length,
          ...results
        );
        if(this.configureconsumerdata['data'].length==0 && this.searchFlag==true)
        {
          this.searchFlag=false
             ElNotification({
            title: 'Warning',
            message: 'No Records Found!',
            type: 'warning',
            duration: 2000,
            position: 'top-right'
          })
        }
        else if(this.configureconsumerdata['data'].length!=0)
        {
          this.searchFlag=true
        }
        

        
      }
    },
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    displayData(data) {
      this.configureconsumerdata = JSON.parse(JSON.stringify(data));
      this.render = true;
      this.initCust.splice(
        0,
        this.configureconsumerdata["data"].length,
        ...this.configureconsumerdata["data"]
      );

    },

    changeStatus(pan, consumerCode) {
      (this.$refs["changeStatusForm"] as any).callChange(pan, consumerCode);
    },
    getConfigureConsumer() {
      this.render = false;
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(
        "/consumerHasManufacturer?manufacturerId=" +
          this.globalData.organizationId
      )
        .then((data) => {
          loading.close();
          this.displayData(data.data);
        })
        .catch((error) => {
          loading.close();
          console.log(error);
        });
    },
    editConsumer(panNo, name, distributorCode) {
      (this.$refs["editConsumerForm"] as any).callUpdate(
        panNo,
        name,
        distributorCode
      );
    },
    attachConsumer() {
      (this.$refs["attachConsumerForm"] as any).callAttach();
    },
    ChangeT(test) {
      this.getConfigureConsumer();
    },
  },
  mounted() {
    this.getConfigureConsumer();
  },
  setup() {
    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "Consumer Name",
        key: "name",
        sortable: true,
      },
      {
        name: "PAN Number",
        key: "panNo",
        sortable: true,
      },
      {
        name: "Industry",
        key: "industry",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "UID",
        key: "id",
        sortable: true,
      },
      {
        name: "Distributor Code",
        key: "distributorCode",
        sortable: true,
      },
      {
        name: "STATUS",
        key: "status",
        sortable: true,
      },
      {
        name: "Action",
        key: "actions",
      },
    ]);
    

    onMounted(() => {
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Configure Consumers", ["Manage"]);
    });

    

    

    
    return {
      tableHeader,
      checkedCustomers,
    };
  },
});
