
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import TheLoader from "@/views/common/TheLoader.vue";
import loading from "vue-loading-overlay";
import Globals from "@/views/common/Globals.vue";
import { hideModal } from "@/core/helpers/dom";
import { DSC, PAN } from "@/helper/globalConst";
import { ElNotification } from "element-plus";
import * as Yup from "yup";
import router from "@/router";

export default defineComponent({
  name: "new-card-modal",
  mixins: [Globals],
  data() {
    return {
      loading: false,
      buttonActive: false,
      group: "",
      formData: {
        application: "",
        organizationType: "",
        organizationId: "",
        data: {
          consumerPanNumber: "",
          distributorCode: "",
          boughtProductInfo: [] as any,
        },
      },
      sampleData: {},
      products: {},
      alreadySelected: [] as any,
      selectedProducts: [] as any,
      panNumber: "",
      panNumberError: "",
      dstCode: "",
      customerName: "",
      category: "",
      product: "",
      productDetails: [] as any,
    };
  },
  methods: {
    buttonfun() {
      this.buttonActive = true;
    },
    showData(check, item) {
      if (check == true && this.selectedProducts.indexOf(item) == -1) {
        this.selectedProducts.push(item);
      } else if (check == false && this.selectedProducts.indexOf(item) > -1) {
        this.selectedProducts.splice(this.selectedProducts.indexOf(item), 1);
      }
    },
    callUpdate(pan, name, dstCode) {
      (this.panNumber = pan),
        (this.customerName = name),
        (this.dstCode = dstCode),
        this.getData();
    },
    setData() {
      this.productDetails = this.products[this.category][this.group];
    },
    clearData() {
      this.productDetails = [];
    },
    formReset() {
      this.productDetails = [];
      this.panNumber = "";
      this.category = "";
      this.group = "";
      this.sampleData = {};
      this.selectedProducts = [];
      this.customerName = "";
      this.panNumberError = "";
      this.dstCode = "";
      // this.getData();
    },
    getData() {
      ApiService.get(
        "/product/consumerHasProduct?panNumber=" +
          this.panNumber +
          "&manufacturerId=" +
          this.globalData.organizationId
      )
        .then((data) => {
          // this.loader=false
          this.sampleData = data.data.data[0];
          this.alreadySelected = data.data.data[1];
          this.selectedProducts = this.alreadySelected.alreadyBoughtProduct;
          this.createData();
        })
        .catch((error) => {
          // this.loader=false
          console.log(error);
          this.sampleData = {};
          // }).then(() => router.push({ name: "sign-in" }));
        });
    },
    createData() {
      this.products = {};
      var obj = {};
      for (let cat in this.sampleData) {
        var obj1 = {};
        for (let grp in this.sampleData[cat]) {
          var obj2 = {};
          for (let prd in this.sampleData[cat][grp]) {
            if (
              this.alreadySelected.alreadyBoughtProduct.indexOf(
                this.sampleData[cat][grp][prd]
              ) == -1
            )
              obj2[this.sampleData[cat][grp][prd]] = false;
            else obj2[this.sampleData[cat][grp][prd]] = true;
          }
          obj1[grp] = obj2;
        }
        obj[cat] = obj1;
      }
      this.products = obj;
      obj = {};
      obj1 = {};
      obj2 = {};
    },
    submit() {
      this.loading = true;
      this.buttonActive = true;
      this.formData.organizationType = "M";
      this.formData.data.distributorCode = this.dstCode;
      this.formData.organizationId = String(this.globalData.organizationId);
      this.formData.data.consumerPanNumber = this.panNumber;
      for (var cat in this.sampleData) {
        for (var grp in this.sampleData[cat]) {
          for (var prd in this.products[cat][grp]) {
            if (this.products[cat][grp][prd] == false) {
              var index = this.sampleData[cat][grp].indexOf(prd);
              if (index > -1) {
                this.sampleData[cat][grp].splice(index, 1);
              }
            }
          }
          if (Object.keys(this.sampleData[cat][grp]).length == 0)
            delete this.sampleData[cat][grp];
        }
        if (Object.keys(this.sampleData[cat]).length == 0)
          delete this.sampleData[cat];
      }
      this.formData.data.boughtProductInfo = [];
      this.formData.data.boughtProductInfo.push(this.sampleData);
      ApiService.put("/product/consumerHasProduct", this.formData)
        .then((data) => {
          this.buttonActive = false;
          (this.$refs["resetForm"] as any).click();
          this.loading = false;
          hideModal(this.attachCustomerModalRef);
          this.$emit("changeTitle", "false");
          ElNotification({
            title: "Success",
            message: "Consumer edited!",
            type: "success",
            duration: 2000,
            position: "top-right",
          });
        })
        .catch((error) => {
          // this.loading=false
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 2000,
            position: "top-right",
          });
        });
      this.sampleData = {};
    },
  },
  // mounted() {
  //   this.getData();
  //   // this.createData();
  // },

  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newCardModalRef = ref<null | HTMLElement>(null);
    const attachCustomerModalRef = ref<null | HTMLElement>(null);

    const validationSchema = Yup.object().shape({
      panNumber: Yup.string()
        .min(10, "PAN number should be exactly 10 alphanumeric")
        .max(10, "PAN number should be exactly 10 alphanumeric")
        .matches(PAN, "PAN number must be valid")
        .required()
        .label("panNumber"),
      distributorCode: Yup.string()
        .matches(DSC, "Distributor code must be valid")
        .min(3, "Distributor code can be between 3 to 8 alphanumeric")
        .max(8, "Distributor code can be between 3 to 8 alphanumeric")
        .required()
        .label("distributorCode"),
      productCategory: Yup.string().label("product Category"),
      productGroup: Yup.string().label("productGroup"),
    });

    // const submit = () => {
    //   if (!submitButtonRef.value) {
    //     return;
    //   }

    //   //Disable button
    //   submitButtonRef.value.disabled = true;
    //   // Activate indicator
    //   submitButtonRef.value.setAttribute("data-kt-indicator", "on");

    //   setTimeout(() => {
    //     if (submitButtonRef.value) {
    //       submitButtonRef.value.disabled = false;

    //       submitButtonRef.value?.removeAttribute("data-kt-indicator");
    //     }

    //     Swal.fire({
    //       text: "Form has been successfully submitted!",
    //       icon: "success",
    //       buttonsStyling: false,
    //       confirmButtonText: "Ok, got it!",
    //       customClass: {
    //         confirmButton: "btn btn-primary",
    //       },
    //     }).then(() => {
    //       hideModal(newCardModalRef.value);
    //     });
    //   }, 2000);
    // };

    return {
      validationSchema,
      submitButtonRef,
      attachCustomerModalRef,
    };
  },
});
